AFRAME.registerComponent("sphere-collision", {
	init() {
		this.el.sceneEl.addEventListener("enter-vr", () => {
			this.el.setAttribute("ammo-shape", "type: sphere; fit: manual; sphereRadius:0.05; offset:0 0 0;")
		})
	}
})

AFRAME.registerComponent("interactable", {
	init() {
		this.el.addEventListener("click", () => {
			this.el.emit('interacted')
		})
		this.el.addEventListener("touched", () => {
			this.el.emit('interacted')
		})
	}
})

AFRAME.registerComponent("usable", {
	init() {
		this.el.addEventListener("triggerdown", () => {
			this.el.emit('used')
		})
		this.el.addEventListener("click", () => {
			this.el.emit('used')
		})
	}
})

AFRAME.registerComponent("grabbable", {
	init() {
	}
})

AFRAME.registerComponent("holdable", {
	schema: {
    offset: {
			type: 'vec3',
			default: '0, 0, 0'
		},
		handOffset: {
			type: 'vec3',
			default: '0, 0, 0'
		},
		rotation: {
			type: 'vec3',
			default: '0, 0, 0'
		},
		handRotation: {
			type: 'vec3',
			default: '0, 0, 0'
		}
  },
	init() {
	}
})

AFRAME.registerComponent("grabber", {
	schema: {
		type: {
			type: 'string',
			default: ''
		},
	},
	tock: function() {
		// Check if collision flag has been set to kinematic body by the holding code
		if (this.collisionFlag === 2 && this.held) {
			// Create the held constraint
			this.el.setAttribute('ammo-constraint', {
				type: 'lock',
				target: this.held
			})

			// Stop deactivation
			this.held.setAttribute('ammo-body', {
				activationState: 'disableDeactivation'
			})

			// Reset target physics
			this.collisionFlag = 0
			this.held.components["ammo-body"].body.setCollisionFlags(this.collisionFlag)
			this.held.components["ammo-body"].updateCollisionFlags()
		}
	},
	init() {
		// Setup VR Attributes
		this.el.sceneEl.addEventListener("enter-vr", () => {
			this.el.setAttribute('ammo-body', {
				disableCollision : true
			})
			if(this.data.type == 'mouse'){
				this.el.pause()
				this.el.setAttribute('visible', false)
			}
			if(this.data.type == 'controller'){
				this.el.play()
			}
		})

		this.el.sceneEl.addEventListener("exit-vr", () => {
			if(this.data.type == 'mouse'){
				this.el.play()
				this.el.setAttribute('visible', true)
			}
			if(this.data.type == 'controller'){
				this.el.pause()
			}
		})

		// Mouse Handler
		if(this.data.type == 'mouse'){
			// Mouse down
			document.addEventListener("mousedown", (e) => {
				if(this.held && !this.target && e.button === 0){
					this.held.emit("used")
				}
				if(this.held && !this.target && e.button === 2){
					this.held.setAttribute('ammo-body', {
						activationState: 'active'
					})
					this.el.removeAttribute('ammo-constraint')
					this.held = null
				}
				if(this.target && e.button === 0) {
					if(this.target.components.holdable) {
						this.target.setAttribute('ammo-body', {
							activationState: 'disableDeactivation'
						})

						// Move target to hands world position and rotation
						const worldPosition = new THREE.Vector3()
						const worldQuaternion = new THREE.Quaternion()
						this.el.object3D.getWorldPosition(worldPosition)
						this.el.object3D.getWorldQuaternion(worldQuaternion)
						const holdPosition = worldPosition
						this.target.object3D.position.set(holdPosition.x, holdPosition.y, holdPosition.z)
						this.target.object3D.setRotationFromQuaternion(worldQuaternion)
						// Translate object to offset hand position
						this.target.object3D.translateX(this.target.components.holdable.data.offset.x)
						this.target.object3D.translateY(this.target.components.holdable.data.offset.y)
						this.target.object3D.translateZ(this.target.components.holdable.data.offset.z)
						this.target.object3D.rotateX(this.target.components.holdable.data.rotation.x * Math.PI/180)
						this.target.object3D.rotateY(this.target.components.holdable.data.rotation.y * Math.PI/180)
						this.target.object3D.rotateZ(this.target.components.holdable.data.rotation.z * Math.PI/180)
						// Set to kinematic and update position
						this.collisionFlag = 2
						this.target.components["ammo-body"].body.setCollisionFlags(this.collisionFlag)
						this.target.components["ammo-body"].syncToPhysics()
						this.held = this.target
					} else if(this.target.components.grabbable) {
						this.target.setAttribute('ammo-body', {
							activationState: 'disableDeactivation'
						})
						this.el.setAttribute('ammo-constraint', {
							type: 'lock',
							target: this.target
						})
						this.held = this.target

						// Listen for drop event
						this.held.addEventListener('drop', () => {
							if(this.held) {
								this.held.setAttribute('ammo-body', {
									activationState: 'active'
								})
								this.el.removeAttribute('ammo-constraint')
								this.held = null
							}
						})
					}
				}
			})

			// Mouse up
			document.addEventListener("mouseup", (e) => {
				if(this.held && this.held.components.grabbable && e.button === 0) {
					this.held.setAttribute('ammo-body', {
						activationState: 'active'
					})
					this.el.removeAttribute('ammo-constraint')
					this.held = null
				}
			})

			// Check for Mouse hover
			this.el.addEventListener("mouseenter", (e) => {
				if (e.detail.intersectedEl.components.grabbable || e.detail.intersectedEl.components.holdable || e.detail.intersectedEl.components.interactable) {
					this.target = e.detail.intersectedEl
					this.el.setAttribute('material', {
						color: "#aaaa88"
					})
				}
			})

			this.el.addEventListener("mouseleave", (e) => {
				this.target = null
				this.el.setAttribute('material', {
					color: "#111111"
				})
			})
		}

		if(this.data.type == 'controller'){
			// Grip down
			this.el.addEventListener("gripdown", () => {
				if(this.target) {
					if(this.target.components.holdable) {
						this.target.setAttribute('ammo-body', {
							activationState: 'disableDeactivation'
						})

						// Move target to hands world position and rotation
						const worldPosition = new THREE.Vector3()
						const worldQuaternion = new THREE.Quaternion()
						this.el.object3D.getWorldPosition(worldPosition)
						this.el.object3D.getWorldQuaternion(worldQuaternion)
						const holdPosition = worldPosition
						this.target.object3D.position.set(holdPosition.x, holdPosition.y, holdPosition.z)
						this.target.object3D.setRotationFromQuaternion(worldQuaternion)
						// Translate object to 
						this.target.object3D.translateX(this.target.components.holdable.data.handOffset.x)
						this.target.object3D.translateY(this.target.components.holdable.data.handOffset.y)
						this.target.object3D.translateZ(this.target.components.holdable.data.handOffset.z)
						this.target.object3D.rotateX(this.target.components.holdable.data.handRotation.x * Math.PI/180)
						this.target.object3D.rotateY(this.target.components.holdable.data.handRotation.y * Math.PI/180)
						this.target.object3D.rotateZ(this.target.components.holdable.data.handRotation.z * Math.PI/180)
						// Set to kinematic and update position
						this.collisionFlag = 2
						this.target.components["ammo-body"].body.setCollisionFlags(this.collisionFlag)
						this.target.components["ammo-body"].syncToPhysics()
						this.held = this.target
					} else if(this.target.components.grabbable) {
						this.target.setAttribute('ammo-body', {
							activationState: 'disableDeactivation'
						})
						this.el.setAttribute('ammo-constraint', {
							type: 'lock',
							target: this.target
						})
						this.held = this.target

						// Listen for drop event
						this.held.addEventListener('drop', () => {
							if(this.held) {
								this.held.setAttribute('ammo-body', {
									activationState: 'active'
								})
								this.el.removeAttribute('ammo-constraint')
								this.held = null
								this.target = null
							}
						})
					}
				} else {
					//Start Colliding
					this.el.setAttribute('ammo-body', {
						disableCollision : false
					})
				}
			})

			// Grip up
			this.el.addEventListener("gripup", () => {
				//Stop Colliding
				this.el.setAttribute('ammo-body', {
					disableCollision : true
				})
				this.el.removeAttribute('ammo-constraint')
				this.held = null
			})

			// Trigger down
			this.el.addEventListener("triggerdown", () => {
				if(this.held && this.held.components.usable){
					this.held.emit('triggerdown')
				}
			})

			// Check for Collision
			this.el.addEventListener("collidestart", (e) => {
				if (e.detail.targetEl.components.grabbable || e.detail.targetEl.components.holdable) {
					this.target = e.detail.targetEl
				}
				if (e.detail.targetEl.components.interactable) {
					e.detail.targetEl.emit('touched', { by: this.el })
				}
			})

			this.el.addEventListener("collideend", (e) => {
				if (e.detail.targetEl === this.held) {
					this.target.setAttribute('ammo-body', {
						activationState: 'active'
					})
					this.target = null
				}
			})
		}
	}
})