AFRAME.registerComponent("plantable", {
})

AFRAME.registerComponent("water", {
})

AFRAME.registerComponent("fertilizer", {
})

AFRAME.registerComponent("meat", {
})

AFRAME.registerComponent("planter", {
	schema: {
    plantStages: {
      type: 'array'
    },
		smokeEmitters: {
			type: 'array'
		},
		mouthHitbox: {
			type: 'string'
		}
  },
	init() {
		this.plantStage = 0

		const plants = []
		this.data.plantStages.forEach(plant => {
			plants.push(document.querySelector(plant))
		});

		const smokers = []
		this.data.smokeEmitters.forEach(smoker => {
			smokers.push(document.querySelector(smoker))
		});

		const mouthHitbox = document.querySelector(this.data.mouthHitbox)

		// Show and Hide events
		plants.forEach(plant => {
			if(plant.components.plant?.data.showEvent){
				this.el.addEventListener(plant.components.plant.data.showEvent, (e) => {
					plant.object3D.visible = true
				})
			}
			if(plant.components.plant?.data.hideEvent){
				this.el.addEventListener(plant.components.plant.data.hideEvent, (e) => {
					plant.object3D.visible = false
				})
			}
		})

		this.el.addEventListener("collidestart", (e) => {
			// Seed
			if(e.detail.targetEl.components["plantable"] && this.plantStage === 0){
				e.detail.targetEl.parentNode.removeChild(e.detail.targetEl);
				plants[0].object3D.visible = true
				plants[0].emit('grow1')
				this.plantStage = 1
			}
			// Fertiliser
			if(e.detail.targetEl.components["fertilizer"] && this.plantStage === 2){
				smokers[3].object3D.position.set(e.detail.targetEl.object3D.position.x, e.detail.targetEl.object3D.position.y, e.detail.targetEl.object3D.position.z)
				smokers[3].components['particle-system'].startParticles()
				setTimeout(() => {  
					// blow up fertilizer bag
					smokers[3].components['particle-system'].stopParticles()
					e.detail.targetEl.parentNode.removeChild(e.detail.targetEl)
				}, 750)
				plants[1].emit('grow2')
				setTimeout(() => {  
					// Emit Smoke
					smokers[1].components['particle-system'].startParticles()
				}, 1750)
				setTimeout(() => {
					plants[1].object3D.visible = false
					plants[2].object3D.visible = true
					plants[2].emit('grow1')
				}, 2500)
				setTimeout(() => {  
					// Stop Smoke
					smokers[1].components['particle-system'].stopParticles()
				}, 3000)
				this.plantStage = 3
			}
		})

		this.el.addEventListener("watered", (e) => {
			if(this.plantStage === 1){
				plants[0].emit('grow2')
				setTimeout(() => {  
					// Emit Smoke
					smokers[0].components['particle-system'].startParticles();
				}, 2000)
				setTimeout(() => {
					plants[0].object3D.visible = false
					plants[1].object3D.visible = true
					plants[1].emit('grow1')
				}, 2500)
				setTimeout(() => {  
					// Stop Smoke
					smokers[0].components['particle-system'].stopParticles();
				}, 3000)
				this.plantStage = 2
			}
		})

		mouthHitbox.addEventListener("collidestart", (e) => {
			if(e.detail.targetEl.components["meat"] && this.plantStage === 3){
				plants[2].removeAttribute('animation-mixer')
				plants[2].setAttribute('animation-mixer', {
					clip: "A_Eating",
					loop: "once",
					timeScale: 1
				})
				e.detail.targetEl.parentNode.removeChild(e.detail.targetEl);
				plants[2].emit('grow2')
				setTimeout(() => {  
					// Emit Smoke
					smokers[2].components['particle-system'].startParticles();
				}, 1500)
				setTimeout(() => {
					plants[2].object3D.visible = false
					plants[3].object3D.visible = true
					plants[3].emit('grow1')
					plants[4].object3D.visible = true
					plants[4].emit('grow1')
				}, 2500)
				setTimeout(() => {  
					// Stop Smoke
					smokers[2].components['particle-system'].stopParticles();
				}, 3000)
				this.plantStage = 4
			}
		})
	}
})

AFRAME.registerComponent("plant", {
	schema: {
		planter: {
			type: 'string'
		},
		roots: {
			type: 'string'
		},
		aggressionBox: {
			type: 'string'
		},
		interestRadius: {
			type: 'string'
		}
  },
	tick() {
		if(this.targets.length > 0 && this.planter.components['planter'].plantStage === 4 && this.attacking === false && this.hurting === false && this.dead === false){
			this.attacking = true
			this.el.removeAttribute('animation-mixer')
			this.el.setAttribute('animation-mixer', {
				clip: "A_Attack",
				loop: "once",
				timeScale: 1
			})
			setTimeout(() => {  
				if(this.targets.length > 0 && this.attacking){
					if (this.targets[0].components['meat']) {
						this.targets[0].parentNode.removeChild(this.targets[0])
						this.targets.splice(0, 1)
					} else if (this.targets[0].id === 'head') {
						console.log('Player takes damage')
					}
				}
			}, 700)
			setTimeout(() => {  
				if(this.attacking === true && this.hurting === false){
					this.attacking = false
					this.el.removeAttribute('animation-mixer')
					this.el.setAttribute('animation-mixer', {
						clip: "Animation"
					})
				}
			}, 2450)
		}
	},
	init() {
		this.planter = document.querySelector(this.data.planter)
		this.roots = document.querySelector(this.data.roots)
		this.targets = []
		this.attacking = false
		this.hurting = false
		this.invincible = false
		this.dead = false
		this.aggressionBoxEl = document.querySelector(this.data.aggressionBox)
		this.interestRadiusEl = document.querySelector(this.data.interestRadius)

		this.aggressionBoxEl.addEventListener("collidestart", (e) => {
			if((e.detail.targetEl.id === 'head' || e.detail.targetEl.components['meat']) && this.planter.components['planter'].plantStage === 4){
				this.targets.push(e.detail.targetEl)
			}
		})
		this.aggressionBoxEl.addEventListener("collideend", (e) => {
			if(this.targets.includes(e.detail.targetEl)){
				const index = this.targets.indexOf(e.detail.targetEl)
				if(index > -1)
				this.targets.splice(index, 1)
			}
		})
		this.el.addEventListener("hurt", (e) => {
			if(this.planter.components['planter'].plantStage === 4 && this.invincible === false && this.dead === false) {
				this.attacking = false
				this.hurting = true
				this.invincible = true
				this.el.removeAttribute('animation-mixer')
				this.el.setAttribute('animation-mixer', {
					clip: "A_Hit",
					loop: "once",
					timeScale: 1
				})
				setTimeout(() => {  
					if(this.invincible === true){
						this.invincible = false
					}
				}, 500)
				setTimeout(() => {  
					if(this.hurting === true && this.invincible === false){
						this.hurting = false
						this.el.removeAttribute('animation-mixer')
						this.el.setAttribute('animation-mixer', {
							clip: "Animation"
						})
					}
				}, 1500)
			}
		})
		this.el.addEventListener("dead", (e) => {
			if(this.dead === false){
				this.invincible = true
				this.hurting = false
				this.attacking = false
				this.dead = true
				this.el.removeAttribute('animation-mixer')
				this.el.setAttribute('animation-mixer', {
					clip: "A_Death",
					loop: "once",
					timeScale: 1,
					clampWhenFinished: true
				})
				this.roots.setAttribute('animation-mixer', {
					clip: "Animation",
					loop: "once",
					timeScale: 1,
					clampWhenFinished: true
				})
				this.el.parentNode.setAttribute('rotate-to-follow', {
					enabled: false
				})
			}
		})
	}
})