function createProjectile(parent, projectiles, mixin, power, muzzle, rotation, sticky, bounciness, temporary, damage) {
	const projectile = document.createElement('a-entity')
	parent.sceneEl.appendChild(projectile)
	const parentWorldPosition = new THREE.Vector3()
	const parentWorldQuaternion = new THREE.Quaternion()
	parent.object3D.getWorldPosition(parentWorldPosition)
	parent.object3D.getWorldQuaternion(parentWorldQuaternion)
	projectile.setAttribute('mixin', mixin)

	// Put projectile in front of parent
	projectile.object3D.position.set(parentWorldPosition.x, parentWorldPosition.y, parentWorldPosition.z)
	projectile.object3D.setRotationFromQuaternion(parentWorldQuaternion)
	projectile.object3D.rotateX(parent.components.holdable.data.rotation.x * Math.PI/180)
	projectile.object3D.rotateY(parent.components.holdable.data.rotation.y * Math.PI/180)
	projectile.object3D.rotateZ(parent.components.holdable.data.rotation.z * Math.PI/180)
	projectile.object3D.translateX(muzzle.x)
	projectile.object3D.translateY(muzzle.y)
	projectile.object3D.translateZ(muzzle.z)

	// Rotate Projectile
	projectile.object3D.rotateX(rotation.x * Math.PI/180)
	projectile.object3D.rotateY(rotation.y * Math.PI/180)
	projectile.object3D.rotateZ(rotation.z * Math.PI/180)

	// Projectile Loaded
	projectile.addEventListener("body-loaded", () => {
    // Set Projectile properties
    projectile.setAttribute('projectile')
    projectile.setAttribute('damage', damage)
		// Set physical properties
		projectile.setAttribute('mixin', mixin)
		if(sticky !== false){
			projectile.setAttribute('sticky')
		}
		if(bounciness !== false){
			projectile.setAttribute('bounce', {
				restitution: bounciness
			})
		}
		if(temporary !== false){
			projectile.setAttribute('temporary', {
				lifespan: temporary
			})
		}

		//Launch projectile
		var velocity = new Ammo.btVector3()
		const projectileWorldDirection = new THREE.Vector3()
		projectiles.object3D.getWorldDirection(projectileWorldDirection)
		velocity.setValue(projectileWorldDirection.x * - 1 * power, projectileWorldDirection.y * - 1 * power, projectileWorldDirection.z * - 1 * power)
		projectile.components["ammo-body"].body.setLinearVelocity(velocity)
	})
}

AFRAME.registerComponent("gun", {
	schema: {
		mixin: {
			type: 'string'
		},
		power: {
			type: 'string'
		},
		muzzle: {
			type: 'vec3'
		},
		rotation: {
			type: 'vec3'
		},
		sticky: {
			default: false
		},
		bounciness: {
			default: 0
		},
		temporary: {
			default: 10
		},
    damage: {
			default: 10
		}
  },
	init() {
		const projectiles = document.createElement('a-entity')
		this.el.appendChild(projectiles)

		//Make projectiles parent entity face correct direction
		projectiles.object3D.rotateX(this.el.components.holdable.data.rotation.x * Math.PI/180)
		projectiles.object3D.rotateY(this.el.components.holdable.data.rotation.y * Math.PI/180)
		projectiles.object3D.rotateZ(this.el.components.holdable.data.rotation.z * Math.PI/180)

		this.el.addEventListener("used", () => {
			createProjectile(this.el, projectiles, this.data.mixin, this.data.power, this.data.muzzle, this.data.rotation, this.data.sticky, this.data.bounciness, this.data.temporary, this.data.damage)
		})
	}
})

AFRAME.registerComponent("projectile", {
	init() {
	}
})

AFRAME.registerComponent("hurtbox", {
	schema: {
    entity: {
      type: 'string'
    }
  },
	init() {
    this.entityEl = document.querySelector(this.data.entity)
    this.el.addEventListener("collidestart", (e) => {
      if(e.detail.targetEl.components["projectile"]){
        this.entityEl.emit('hurt')
        if(this.entityEl.components["health"]){
          this.entityEl.setAttribute('health', this.entityEl.components["health"].data - e.detail.targetEl.components["damage"].data)
        }
        e.detail.targetEl.setAttribute('damage', e.detail.targetEl.components["damage"].data / 2)
      }
    })
	}
})

AFRAME.registerComponent("damage", {
  schema: {
    type: 'number'
  },
	init() {
	}
})

AFRAME.registerComponent("health", {
  schema: {
    type: 'number'
  },
  update() {
    if(this.data <= 0){
      this.el.emit('dead')
    }
  },
	init() {

	}
})