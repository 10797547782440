AFRAME.registerComponent("sprint", {
	init() {
		window.addEventListener("keydown", (event) => {
			if(event.code === 'ShiftLeft'){
				this.el.setAttribute('movement-controls', {
					speed: 0.3
				})
			}
		})
		window.addEventListener("keyup", (event) => {
			if(event.code === 'ShiftLeft'){
				this.el.setAttribute('movement-controls', {
					speed: 0.15
				})
			}
		})
	}
})

AFRAME.registerComponent('touch-listener', {
	init: function () {  
		window.addEventListener("touchstart", (event) => {
			this.el.emit('touchdown')
		})
		window.addEventListener("touchend", (event) => {
			this.el.emit('touchup')
		})
	}
})

AFRAME.registerComponent('vr-navmesh-disable', {
	init: function () {
		window.addEventListener("enter-vr", (event) => {
			this.el.setAttribute('simple-navmesh-constraint', {
				enabled: 'false'
			})
		})
		window.addEventListener("exit-vr", (event) => {
			this.el.setAttribute('simple-navmesh-constraint', {
				enabled: 'true'
			})
		})
	}
})