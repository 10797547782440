AFRAME.registerComponent("teleportable", {
	init() {
	}
})

AFRAME.registerComponent("teleporter", {
	schema: {
		exit: {
			type: 'string'
		},
		hitbox: {
			type: 'string'
		}
  },
	step: function() {
		// Check if collision flag has been set to kinematic body by the holding code
		if (this.teleporting) {
			this.teleporting.emit('drop')
			this.teleporting.components["ammo-body"].body.setLinearVelocity(new Ammo.btVector3(0, 0, 0))
			this.teleporting.components["ammo-body"].updateCollisionFlags()
			this.teleporting = null
		}
	},
	init() {
		this.el.disabled = false
		this.exit = document.querySelector('#' + this.data.exit)
		this.hitbox = document.querySelector('#' + this.data.hitbox)

		// Events
		this.hitbox.addEventListener("collidestart", (e) => {
			if(e.detail.targetEl.components["teleportable"] && !this.el.disabled){
				this.exit.emit('disable')

				this.teleporting = e.detail.targetEl
				const objectQuaternion = new THREE.Quaternion()
				this.teleporting.object3D.getWorldQuaternion(objectQuaternion)
				const teleportHeight = this.teleporting.object3D.position.y - this.el.object3D.position.y
				const teleporterQuaternion = new THREE.Quaternion()
				this.exit.object3D.getWorldQuaternion(teleporterQuaternion)

				this.teleporting.object3D.position.x = this.exit.object3D.position.x
				this.teleporting.object3D.position.y = this.exit.object3D.position.y
				this.teleporting.object3D.position.z = this.exit.object3D.position.z
				this.teleporting.object3D.setRotationFromQuaternion(teleporterQuaternion)
				this.teleporting.object3D.translateY(teleportHeight)
				this.teleporting.object3D.setRotationFromQuaternion(objectQuaternion)
				this.teleporting.components["ammo-body"].body.setCollisionFlags(2)
				this.teleporting.components["ammo-body"].syncToPhysics()
				setTimeout(() => { this.step() }, 50);
			}
		})
		this.hitbox.addEventListener("collideend", () => {
			if(this.el.disabled){
				this.el.disabled = false;
			}
		})
		this.el.addEventListener('disable', () => {
			this.el.disabled = true;
		})
	}
})

AFRAME.registerComponent("spraybottle", {
	schema: {
		emitter: {
			type: 'string'
		},
		length: {
			type: 'number',
			default: 200
		},
		raycaster: {
			type: 'string'
		}
	},
	init() {
		const emitter = document.querySelector(this.data.emitter)
		const raycaster = document.querySelector(this.data.raycaster)
		this.el.sceneEl.addEventListener("enter-vr", () => {
			this.el.setAttribute('raycaster', {
				direction: "0 0 1",
				far: "2",
				objects: ".sprayable",
				interval: "100",
				origin: "0 0.125 0"
			})
		})
		this.el.sceneEl.addEventListener("exit-vr", () => {
			this.el.removeAttribute('raycaster')
		})
		this.el.addEventListener("used", () => {
			this.el.removeAttribute('animation-mixer')
			this.el.setAttribute('animation-mixer', {
				clip: "SM_Bottle_Spray.001Action",
				loop: "once",
				timeScale: 1.2
			})
			emitter.components['particle-system'].startParticles()
			setTimeout(() => {
				emitter.components['particle-system'].stopParticles()
				if(this.el.components.raycaster?.intersections?.length > 0){
					this.el.components.raycaster.intersections[0].object.el.emit('watered')
				} else if(raycaster.components.raycaster?.intersections?.length > 0) {
					raycaster.components.raycaster.intersections[0].object.el.emit('watered')
				}
			}, this.data.length)
		})
	}
})